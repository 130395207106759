import { config } from "@fortawesome/fontawesome-svg-core"
import PropTypes from "prop-types"
import React from "react"
import { createGlobalStyle } from "styled-components"
import { initFontAwesome } from "../utils/fontawesome"
import { Container } from "./BaseComponents/container"
import SEO from "./BaseComponents/SEO"
// https://github.com/FortAwesome/react-fontawesome/issues/134#issuecomment-471940596
// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css"
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false

const FixHorizontalScroll = createGlobalStyle`
  body {
    overflow-x: hidden;
  }
`

// every page uses layout so load fontawesome here
initFontAwesome()

const Layout = ({ children, noPadding, containerStyle, hideFooter }) => (
  <>
    <SEO />
    <FixHorizontalScroll />
    <Container noPadding={noPadding} css={containerStyle}>
      {children}
    </Container>
  </>
)

Layout.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  containerStyle: PropTypes.string,
  hideFooter: PropTypes.bool,
}

Layout.defaultProps = {
  children: null,
  noPadding: false,
  containerStyle: "",
  hideFooter: false,
}

export default Layout
